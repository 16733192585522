// Dependencies
import React, { useEffect } from "react"
import { motion } from "framer-motion"
// Hooks & Helpers
import { easeOutInQuint } from "../helpers/easings"
// Components
import Link from "../components/Link"
import Logotype from "./Logotype"

export const PURPOSE_SECTION = '#purpose'
export const STRATEGY_SECTION = '#strategy'
export const EDITORIAL_SECTION = '#editorial'
export const CONTACT_SECTION = '#contact'


const NavigationLogo = ({ showLogo, finishIntro }) => {

	const animationProps = {
		style: {
			x: '-50%'
		},
		initial: {
			y: -150,
			opacity: 0
		},
		animate: {
			y: showLogo ? 0 : -150,
			opacity: showLogo ? 1 : 0
		},
		transition: {
			duration: 2,
			ease: easeOutInQuint
		}
	}

	return (
		<motion.div className="logo" {...animationProps}>
			<Link to="/"><Logotype /></Link>
		</motion.div>
	)
}

const NavLink = ({ to, currentSection, children }) => {

	return (
		<Link 
			to={to} 
			className={`a1 ${to === currentSection ? 'active' : ''}`}
		>
			{children}
		</Link>
	)
}

const Navigation = (props) => {

	const showLogo = props.currentSection !== PURPOSE_SECTION

	useEffect(() => {
		if (props.location.hash) {
			window.scrollTo(0, 0);
		}
	}, [])

	return (
		<nav className={`${!props.finishIntro ? 'hide' : ''} ${showLogo ? 'show-logo' : ''}`}>
			<NavigationLogo showLogo={showLogo} />
			<div className="container nav-links padx2">
				<span>
					<NavLink to={PURPOSE_SECTION} {...props}>Purpose</NavLink>
					<NavLink to={STRATEGY_SECTION} {...props}>Strategy</NavLink>
				</span>
				<span>
					<NavLink to={EDITORIAL_SECTION} {...props}>Editorial</NavLink>
					<NavLink to={CONTACT_SECTION} {...props}>Contact</NavLink>
				</span>
			</div>
		</nav>
	)
}

export default Navigation
