// Dependencies
import React, { useState } from "react"
import { AnimatePresence } from "framer-motion"
import useMouse from "../hooks/useMouse"
import Asset from "../components/Asset"
import Overlay from "../components/Overlay"
import Slideshow, { useSlideshow } from "../components/Slideshow"

const Cursor = ({ text }) => {

	const { x, y } = useMouse()

	return (
		<div className="pfix cursor a1" style={{ left: x, top: y }}>{text}</div>
	)
}

const EditorialProject = ({ content, handleClose }) => {

	const slideshowState = useSlideshow({
		length: content.length,
		initial: 0
	})

	const [i, next, prev] = slideshowState

	const [cursorText, setCursorText] = useState(null)

	const isVideo = content[i]?.file?.contentType.includes('video')
	
	const videoHandlers = {
		onMouseEnter: () => setCursorText("Watch"),
		onMouseLeave: () => setCursorText(null)
	}

	return (
		<Overlay>

			{/* Slideshow images */}
			<Slideshow state={slideshowState}>
				{content.map((asset, index) => (
					<Asset 
						{...asset} 
						handlers={videoHandlers}
						key={index} 
					/>
				))}
			</Slideshow>

			{/* Next/Prev UI */}
			{!isVideo && (
				<>
					<button 
						type="button" 
						className="slideshow-hotspot prev silent" 
						onClick={prev} 
						onMouseEnter={() => setCursorText("Prev")}
						onMouseLeave={() => setCursorText(null)} 
					/>
					<button 
						type="button" 
						className="slideshow-hotspot next silent" 
						onClick={next} 
						onMouseEnter={() => setCursorText("Next")} 
						onMouseLeave={() => setCursorText(null)}
					/>
				</>
			)}
			
			{/* Close button and touch UI */}
			<div className="slideshow-controls align-center pabs w100 z-top">
				<button type="button" className="padx2 silent" onTouchEnd={prev}>
					<svg width="27" height="49" viewBox="0 0 27 49" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M25.4601 46.67L2.83008 24.04L25.4601 1.40997" stroke="black" strokeWidth="4" strokeMiterlimit="10"/>
					</svg>
				</button>
				<button type="button" className="a1" onClick={handleClose}>Close</button>
				<button type="button" className="padx2 silent" onTouchEnd={next}>
					<svg width="27" height="49" viewBox="0 0 27 49" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.40991 1.41003L24.0399 24.04L1.40991 46.67" stroke="black" strokeWidth="4" strokeMiterlimit="10"/>
					</svg>
				</button>
			</div>

			{cursorText && <Cursor text={cursorText} />}
		</Overlay>
		
	)
}

export default EditorialProject