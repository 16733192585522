import { useState, useEffect, useRef } from 'react'

export default function useIntersection (options)  {
	const ref = useRef()
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      options
    )

    observer.observe(ref.current)
    return () => { observer.disconnect() }
  }, [ref])

  return [ref, isIntersecting]
}
