// Dependencies
import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'

export function useSlideshow({ length, initial }) {
	const [index, set] = useState(initial || 0)

	const hasNextSlide = index < length - 1
	const hasPrevSlide = index > 0

	const next = () => {
		set(prev => prev < length - 1 ? prev + 1 : 0)
	}

	const prev = () => {
		set(prev => prev > 0 ? prev - 1 : length - 1)
	}

	return [index, next, prev, hasNextSlide, hasPrevSlide]
}

const Slideshow = ({ state, children }) => {

	const [currentIndex] = state

	const revealAnimation = {
		initial: {
			opacity: 0,
			scale: 0.8
		},
		animate: {
			opacity: 1,
			scale: 1
		},
		exit: {
			opacity: 0
		}
	}

	const slideAnimation = {
		initial: {
			opacity: 0
		},
		animate: {
			opacity: 1
		},
		exit: {
			opacity: 0
		}
	}
	
	return (
		<motion.div className="slideshow" {...revealAnimation}>
			{React.Children.map(children, (child, index) => (
				index === currentIndex ? (
					<motion.div className="slide" {...slideAnimation} key={index}>
						{child}
					</motion.div>
				) : null
			))}
		</motion.div>
	)
}

export default Slideshow