// Dependencies
import React from "react"
// Components
import Image from "../components/Image"
import Video from "../components/Video"

const Asset = ({ file, large, handlers }) => (
	<div className="slide-asset">
		{file ? file.contentType.includes('video') ? (
			<div {...handlers}>
				<Video src={file.url} />
			</div>
		) : large ? (
			<Image image={large} />
		) : null : null}
	</div>
)

export default Asset