// Dependencies
import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion"
// Hooks & Helpers
import useIntersection from "../hooks/useIntersection"
import { easeOutInQuint } from '../helpers/easings'
// Wrappers
import Layout from "../wrappers/Layout"
// Components
import BackgroundChange from "../components/BackgroundChange"
import Image from "../components/Image"
import Logotype from "../components/Logotype"
import EditorialProject from "../components/EditorialProject"
import Navigation, { PURPOSE_SECTION, STRATEGY_SECTION, EDITORIAL_SECTION, CONTACT_SECTION } from "../components/Navigation"
import Reveal from "../components/Reveal"
import RichText from "../components/RichText"
import { QuoteLine } from "../components/Quote"
import StrategyCopy, { useStrategyState, WHITE, OFF_WHITE } from "../components/StrategyCopy"
import Testimonials from "../components/Testimonials"


const LoopPoint = () => {

	const [ref, inView] = useIntersection({ threshold: 0 })

	useEffect(() => {
		if (inView) {
			window.scrollTo(0, 0)
		}
	}, [inView])

	return (
		<div className="w100 mq-mouse-only" style={{ height: 2 }} ref={ref} />
	)
}

const Mission = ({ 
	tagline, 
	loop, 
	onVisible, 
	onLogoAnimationComplete,
	hideTagline,
	...props 
}) => {

	const [ref, inView] = useIntersection({ threshold: 0.5 })

	useEffect(() => {
		if (inView) {
			!onVisible || onVisible()
		}
	}, [inView])

	const taglineAnimation = !loop ? {
		initial: {
			x: -50,
			opacity: 0
		},
		whileInView: {
			x: !hideTagline ? 0 : -50,
			opacity: !hideTagline ? 1 : 0
		},
		viewport: {
			once: true
		},
		transition: {
			duration: 4,
			ease: easeOutInQuint
		}
	} : {}

	return (
		<section {...props} style={{ overflowX: 'hidden' }} ref={ref}>
			<div className="tagline">
				<motion.h1 className="prel p1 max-xl" {...taglineAnimation}>
					<QuoteLine />
					{tagline.text}
				</motion.h1>
			</div>
			<div className="logotype">
				<Logotype 
					svgClassName="fill-blue" 
					willAnimate={!loop} 
					onLogoAnimationComplete={onLogoAnimationComplete} 
				/>
			</div>

			{loop && <LoopPoint />}
		</section>
	)
}

const EditorialListItem = ({
	title,
	thumbnail,
	subtitle,
	onClick,
}) => {

	const variants = {
		show: {
			x: 0,
			opacity: 1
		},
		hide: {
			x: 20,
			opacity: 0
		}
	}

	const transition = {
		duration: 1.2,
		ease: easeOutInQuint
	}

	return (
		<motion.div className="mbx2" variants={variants} transition={transition}>
			<button type="button" className="editorial-link" onClick={onClick} >
				<h2 className="">{title}</h2>
				<img className="thumb" src={thumbnail.small.images.fallback.src} />
				<h3 className="a1">{`(${subtitle})`}</h3>
			</button>
		</motion.div>
	)
}

const EditorialList = ({ editorialWork, setActiveProject, ...props }) => {

	const transition = {
		duration: 1,
		ease: easeOutInQuint
	}

	const variants = {
		show: {
			// transition: { staggerChildren: 0.07, delayChildren: 0.2 },
			transition: { staggerChildren: 0.08, delayChildren: 1, ...transition }
		},
		hide: {
			transition: { staggerChildren: 0.08, staggerDirection: -1, ...transition }
		}
	}

	return (
		<motion.div {...props} variants={variants} whileInView="show" initial="hide" viewport={{ once: true }}>
			{editorialWork?.map((entry, index) => {
				return (
					<EditorialListItem {...entry} index={index} onClick={() => setActiveProject(entry)} key={entry.title} />
				)
			})}
		</motion.div>
	)
}

const StrategyCopyBlock = ({ content }) => {
	const state = useStrategyState()
	return (
		<span className="first-block" style={{
			color: !state.activeId ? WHITE : OFF_WHITE,
			transition: 'color 1s'
		}}>
			<StrategyCopy content={content} {...state} />
		</span>
	)
}

const IndexPage = ({ data, location }) => {

	const {
		tagline,
		strategyHeading,
		strategyDownload,
		strategyCopy,
		strategyApproach,
		testimonials,
		editorialHeading,
		editorialWork,
		contactImage,
		contactCopy,
		contactLinks
	} = data.allContentfulHomepage.nodes[0]

	const [activeProject, setActiveProject] = useState(null)
	const [currentSection, setCurrentSection] = useState(PURPOSE_SECTION)
	const [finishIntro, setFinishIntro] = useState(false)

	const missionProps = {
		className: 'hero container',
		tagline: tagline,
	}

	return (
		<Layout>
			<Navigation currentSection={currentSection} location={location} finishIntro={finishIntro} />
			<BackgroundChange foreground={'var(--blue)'} background={'var(--white'} />
			
			<Mission 
				id="mission" 
				{...missionProps}
				hideTagline={!finishIntro}
				onVisible={() => setCurrentSection(PURPOSE_SECTION)}
				onLogoAnimationComplete={() => setFinishIntro(true)}
			/>

			<section id="strategy" className="container section-padding">
				<BackgroundChange 
					foreground={'var(--white)'} 
					background={'var(--blue'} 
					onVisible={() => {
						setCurrentSection(STRATEGY_SECTION)
						setFinishIntro(true)
					}}
				/>
				<div className="grid-16">
					<Reveal className="span-6 start-2 p1 mbx2" delay={0.5}>
						<RichText content={strategyHeading} />
						{strategyDownload && (
							<div className="outerx2">
								<a href={strategyDownload?.file?.url} className="a1 download-link" download={strategyDownload?.title} target="_blank">
									<span>Find out more</span>
									<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path fillRule="evenodd" clipRule="evenodd" d="M6.19605 7.27842V0.272736H5.25855V7.27842L2.55685 4.57671L1.90912 5.23296L5.7273 9.05114L9.55401 5.23296L8.88923 4.57671L6.19605 7.27842ZM0 11H11V10H0V11Z" fill="black"/>
									</svg>
								</a>
							</div>		
						)}
					</Reveal>
					<div className="span-6 start-9">
						<Reveal className="max-m p2 has-links strategy-copy first-block" delay={1}>
							<StrategyCopyBlock content={strategyCopy} />
						</Reveal>
					</div>
				</div>
			</section>

			<section id="approach" className="container section-padding">
				<div className="grid-16">
					<div className="outerx4 innerx4 span-10 start-4">
						<BackgroundChange 
							foreground={'var(--white)'} 
							background={'var(--black'}
						/>
						<Reveal className="p1">
							<RichText content={strategyApproach} />
						</Reveal>
					</div>
				</div>
			</section>

			<section id="testimonials">
				<Testimonials content={testimonials} />
			</section>

			<section id="editorial" className="container section-padding">
				<BackgroundChange 
					foreground={'var(--blue)'} 
					background={'var(--grey'}
					onVisible={() => setCurrentSection(EDITORIAL_SECTION)}
				/>
				<div className="grid-16">
					<Reveal className="span-6 start-2 p1" delay={0.5}>
						<RichText content={editorialHeading} />
					</Reveal>
				</div>
				<div className="grid-16">
					<div className="span-12 start-6">
						<EditorialList 
							className="innerx4" 
							editorialWork={editorialWork} 
							setActiveProject={setActiveProject} 
						/>
					</div>
				</div>
			</section>

			<section id="contact" className="container section-padding">
				<BackgroundChange 
					foreground={'var(--blue)'} 
					background={'var(--white'}
					onVisible={() => setCurrentSection(CONTACT_SECTION)}
				/>
				<div className="grid-16">
					<Reveal className="span-6 start-2 mbx2" delay={0.5}>
						<Image image={contactImage.large} />
					</Reveal>
					<Reveal className="span-8 start-9" delay={1}>
						<div className="has-links p1">
							<RichText content={contactCopy} />
						</div>
						<div className="outerx4 p3 contact-links">
							<RichText content={contactLinks} />
						</div>
					</Reveal>
				</div>
			</section>

			<Mission 
				id="loop" 
				{...missionProps}
				onVisible={() => setCurrentSection(PURPOSE_SECTION)}
				loop
			/>
			
			<AnimatePresence>
				{activeProject && <EditorialProject {...activeProject} handleClose={() => setActiveProject(null)} />}
			</AnimatePresence>
		</Layout>
	)
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default IndexPage

export const IndexPagePageQuery = graphql`

	fragment AssetDefault on ContentfulAsset {
		file {
			contentType
			url
		}
		large: gatsbyImageData(
			layout: FULL_WIDTH
		)
	}

	fragment Testimonial on ContentfulTestimonial {
		name
		role
		quote {
			raw
		}
	}

	fragment EditorialProject on ContentfulProject {
		createdAt
		id
		title
		slug
		year
		thumbnail {
			file {
				url
			}
			small: gatsbyImageData(
				layout: FIXED
				aspectRatio: 0.75
				width: 400
				cropFocus: FACES
				resizingBehavior: FILL
			)
		}
		subtitle
		externalLink
		content {
			...AssetDefault
		}
	}

  query IndexPageQuery {
    allContentfulHomepage(limit: 1) {
			nodes {
				tagline {
					text: tagline
				}
				strategyHeading {
					raw
				}
				strategyDownload {
					title
					file {
						url
					}
				}
				strategyCopy {
					raw
					references {
						... on ContentfulStrategyCopy {
							__typename
							contentful_id
							isInline
							copy {
								raw
								references {
									... on ContentfulStrategyCopy {
										__typename
										contentful_id
										isInline
										copy {
											raw
										}
									}
									... on ContentfulAsset {
										__typename
										contentful_id
										file {
											url
										}
									}
								}
							}
						}
					}
				}
				strategyApproach {
					raw
				}
				testimonials {
					...Testimonial
				}
				editorialHeading {
					raw
				}
				editorialWork {
					...EditorialProject
				}
				contactImage {
					...AssetDefault
				}
				contactCopy {
					raw
				}
				contactLinks {
					raw
				}
			}
    }
  }
`
