// Dependencies
import React, { useEffect } from "react"
// Hooks & Helpers
import useIntersection from "../hooks/useIntersection"

const BackgroundChange = ({ foreground, background, onVisible }) => {

	const [ref, inView] = useIntersection({ threshold: 1 })

	useEffect(() => {
		if (inView) {
			document.documentElement.style.setProperty('--background', background);
			document.documentElement.style.setProperty('--foreground', foreground);
			!onVisible || onVisible()
		}
	}, [inView])

	return (
		<div className="w100" ref={ref} />
	)
}

export default BackgroundChange