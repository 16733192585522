// Dependencies
import React from "react"
import { motion } from "framer-motion"
// Hooks & Helpers
import { easeOutInQuint } from '../helpers/easings'

const Reveal = ({ children, delay, ...props }) => {

	const animation = {
		initial: { 
			y: '3vh',
			opacity: 0
		},
		whileInView: { 
			y: 0,
			opacity: 1 
		},
		viewport: { once: true },
		transition: {
			duration: 2,
			ease: easeOutInQuint,
			delay
		}
	}

	return (
		<motion.div {...props} {...animation}>
			{children}
		</motion.div>
	)
}

export default Reveal