// Dependencies
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image" 
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/

const Image = (props) => {

	const { width, height, images } = props.image || {}

	return (
		props && props.proHeight && props.image ? (
			<div id={props.id} className="image proheight">
				<img 
					alt="" 
					role="presentation" 
					aria-hidden="true" 
					src={`data:image/svg+xml;charset=utf-8,%3Csvg height='${height}' width='${width}' xmlns='http://www.w3.org/2000/svg' version='1.1'%3E%3C/svg%3E`} 
				/>
				<picture>
					{images.sources.map((sourceAttributes) => (
						<source {...sourceAttributes} key={sourceAttributes.srcSet} />
					))}
					<img {...images.fallback} alt={props.alt || ""} />
				</picture>
			</div>
		) : (
			<GatsbyImage className="image" alt="" {...props} />
		)
	)
}

export default Image