// Dependencies
import React, { useEffect, useState, useRef } from "react"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import { easeOutInQuint } from '../helpers/easings'
import Quote from "../components/Quote"

// const isBrowser = typeof window !== "undefined"

const Testimonials = ({ content }) => {

	const ref = useRef()
	const { scrollY } = useViewportScroll()
	const x = useTransform(scrollY, (value) => {
		const top = ref.current ? ref.current.getBoundingClientRect().top : 1000
		const velocity = value * -0.1
		return velocity + top
		// return isBrowser ? velocity + top : velocity + (top + window.innerWidth)
	})

	const quoteAnimation = {
		initial: {
			x: 80,
			opacity: 0
		},
		whileInView: {
			x: 0,
			opacity: 1
		},
		viewport: {
			once: true
		},
		transition: {
			duration: 2,
			ease: easeOutInQuint
		}
	}

	return (
		<div className="testimonials" ref={ref}>
			{/* Testimonial gallery */}
			<div className="quote-gallery">
				<motion.div className="quote-gallery-inner" style={{ x: x }}>
					{content?.map((entry, index) => {
						return (
							<Quote 
								className="quote" 
								{...entry} 
								quoteAnimation={quoteAnimation}
								hasLine
								key={entry.name}
							/>
						)
					})}
				</motion.div>
			</div>
			{/* Shadow quotes which define the vertical scroll distance */}
			{content?.map((entry, index) => {
				return (
					<Quote className="shadow-quote" {...entry} key={`${entry.name}-shadow`} />
				)
			})}
			<div style={{ height: '33vh' }} />
		</div>
	)
}

export default Testimonials