// Dependencies
import React from "react"
import { motion } from "framer-motion"
import { easeOutInQuint } from '../helpers/easings'
import RichText from "../components/RichText"

export const QuoteLine = () => {

	const lineAnimation = {
		initial: {
			scaleY: 0
		},
		whileInView: {
			scaleY: 1
		},
		viewport: {
			once: true
		},
		style: {
			transformOrigin: 'top left'
		},
		transition: {
			duration: 4,
			ease: easeOutInQuint
		}
	}

	return <motion.div className="quote-border" {...lineAnimation} />
}

const Quote = ({ quote, name, role, quoteAnimation, hasLine, ...props }) => (
	<div {...props}>
		<motion.div {...quoteAnimation} className="quote-wrapper">
			{hasLine && <QuoteLine />}
			<div className="p2 mbx2 quote-copy">
				<RichText content={quote} />
			</div>
			<h3>{name}</h3>
			<h3>{role}</h3>
		</motion.div>
	</div>
)

export default Quote