// Dependencies
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Typed from 'typed.js'
// Hooks & Helpers
// ....
// Components
// ...

export function useStrategyState() {

	const [activeId, setActive] = useState(null)

	useEffect(() => {
		let timer
		if (activeId) {
			timer = setTimeout(() => {
				setActive(null)
			}, 5000)
		}
		return () => clearTimeout(timer)
	}, [activeId])

	return {
		activeId,
		setActive
	}
}

// [INLINES.HYPERLINK]
const Hyperlink = ({ data, children }) => {
	const isHash = data.uri?.includes('#')

	return (
		isHash ? (
			<Link to={data.uri}>{children}</Link>
		) : (
			<a href={data.uri} target="_blank" rel="noopener noreferrer">{children}</a>
		)
	)
}

// [INLINES.ASSET_HYPERLINK]
const DownloadLink = ({ data, children }) => {
	const file = data.target && data.target.file;
	return (
		file ? (
			<a href={file.url} download={file.title} target="_blank">{children}</a>
		) : (
			<span className="c-constraints">[Asset missing]</span>
		)
	)
}

// [INLINES.ENTRY_HYPERLINK]

export const WHITE = 'rgba(255,255,255,1)'
export const OFF_WHITE = 'rgba(255,255,255,0.33)'

const Snippet = ({ data, children, setActive, activeId }) => {

	const id = data?.target?.contentful_id
	const isActive = id === activeId
	
	const [show, set] = useState(false)

	useEffect(() => {
		if (show) {
			setActive(id)
		}
	}, [show])
  
	return (
		<span style={{
			color: isActive || !activeId ? WHITE : OFF_WHITE,
			transition: 'color 1s'
		}}>
			<button type="button" className={!show ? 'link' :  'italic link--reverse'} onClick={() => set(o => !o)}>{children}</button>
			{show && data.target.copy && (
				<span className={`nested-snippet ${data.target.isInline ? 'is-inline' : 'is-para'}`}>
					<StrategyCopy 
						content={data.target.copy} 
						isInline={data.target.isInline}
						setActive={setActive}
						activeId={activeId}
					/>
				</span>
			)}
		</span>
	)
}

// function useTyping(string) {

// 	return 
// }

const P = ({ children }) => {

	return (
		<span className="para">{children}</span>
	)
}

const Text = ({ children }) => {

	const el = useRef()
	const typed = useRef()
	const options = {
		strings: [children],
		typeSpeed: 25,
		showCursor: false
	}

	useEffect(() => {
		typed.current = new Typed(el.current, options)
		return () => typed.current.destroy()
	}, [])

	return <span className="text-node" ref={el} />
}

const PlainText = ({ children }) => children

const options = (props) => {
	return {
		// renderMark: {
		//   [MARKS.BOLD]: text => <Bold>{text}</Bold>,
		//   [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
		// },
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <P {...node}>{children}</P>,
			[INLINES.HYPERLINK]: (node, children) => <Hyperlink {...node}>{children}</Hyperlink>,
			[INLINES.ASSET_HYPERLINK]: (node, children) => <DownloadLink {...node}>{children}</DownloadLink>,
			[INLINES.ENTRY_HYPERLINK]: (node, children) => <Snippet {...node} {...props}>{children}</Snippet>
		},
		renderText: text => text.split('\n').reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, <PlainText key={index}>{textSegment}</PlainText>], []),
		// renderText: (text) => <Text text={text} />
		// renderText: text => text.split('\n')
		// 	.reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [])
	}
}

const StrategyCopy = ({ content, ...props }) => {

	return (
		content && (
			renderRichText(content, options(props))
		)
	)

	// const el = useRef()

	// useEffect(() => {
	// 	if (el.current) {
	// 		console.log(el.current.textContent)
	// 	}
	// }, [])

	// return (
	// 	<div ref={el}>
	// 		{content && (
	// 			renderRichText(content, options(props))
	// 		)}
	// 	</div>
	// )
}

StrategyCopy.propTypes = {
  content: PropTypes.shape({
		raw: PropTypes.string.isRequired,
		references: PropTypes.array
	})
}

export default StrategyCopy