// https://pqina.nl/blog/how-to-prevent-scrolling-the-page-on-ios-safari/
// Dependencies
import React, { useEffect, useRef } from "react"
// Hooks & Helpers
import useWindowHeight from '../hooks/useWindowHeight'
// Components
// ...
// Styles & Files
import "../css/components/overlay.scss"

function useLockScroll() {
	const ref = useRef()

	useEffect(() => {
		let scrollY;

		function preventDefault(e) {
			e.preventDefault();
		}

		function showModal() {
			// remember scroll position
  		scrollY = window.scrollY;
			// Lock body scroll
			document.documentElement.classList.add("is-locked");
			// block pointer events
			if (ref.current) {
				ref.current.addEventListener("pointermove", preventDefault);
			}
		}

		function hideModal() {
			document.documentElement.classList.remove("is-locked");
			// resume pointer events
			if (ref.current) {
				ref.current.removeEventListener("pointermove", preventDefault);
			}
			// restore scroll position
  		window.scrollTo(0, scrollY);
		}

		showModal()
		return () => {
			hideModal()
		}
	}, [])

	return ref
}

const Overlay = ({ willScroll, children }) => {

	const ref = useRef() // useLockScroll()

	// useWindowHeight()

	return (
		<div className="overlay" style={{
			overflowX: "hidden",
			overflowY: willScroll ? "scroll" : "hidden",
		}} ref={ref}>
			{children}
		</div>
	)
}

export default Overlay
