// Dependencies
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
// Hooks & Helpers
// ....
// Components
// ...

// [INLINES.HYPERLINK]
const Hyperlink = ({ data, children }) => {
	// console.log(data);
	return (
		<a href={data.uri} target="_blank" rel="noopener noreferrer">{children}</a>
	)
}

// [INLINES.ASSET_HYPERLINK]
const DownloadLink = ({ data, children }) => {
	const file = data.target && data.target.file;
	return (
		file ? (
			<a href={file.url} download={file.title} target="_blank">{children}</a>
		) : (
			<span className="c-constraints">[Asset missing]</span>
		)
	)
}

// [INLINES.ENTRY_HYPERLINK]
const Snippet = ({ data, children }) => {
	
	const [show, set] = useState(false)
  
	return (
		<span>
			<button type="button" className="link" onClick={() => set(true)}>{children}</button>
			{show && data.target.copy && (
				<span className="outerx2 block">
					<RichText content={data.target.copy} />
				</span>
			)}
		</span>
	)
}

const options = {
  // renderMark: {
  //   [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  //   [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
  // },
  renderNode: {
    // [BLOCKS.PARAGRAPH]: (_node, children) => <P>{children}</P>,
    // [BLOCKS.HEADING_1]: (_node, children) => <H1>{children}</H1>,
    // [BLOCKS.HEADING_2]: (_node, children) => <H2>{children}</H2>,
    // [BLOCKS.HEADING_3]: (_node, children) => <H3>{children}</H3>,
    // [BLOCKS.HEADING_4]: (_node, children) => <H4>{children}</H4>,
    // [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    // [BLOCKS.EMBEDDED_ENTRY]: (node, children) => <EmbeddedEntry {...node}>{children}</EmbeddedEntry>,
    // [BLOCKS.EMBEDDED_ASSET]: (node, children) => <Image {...node}>{children}</Image>,
		[INLINES.HYPERLINK]: (node, children) => <Hyperlink {...node}>{children}</Hyperlink>,
		[INLINES.ASSET_HYPERLINK]: (node, children) => <DownloadLink {...node}>{children}</DownloadLink>,
		[INLINES.ENTRY_HYPERLINK]: (node, children) => <Snippet {...node}>{children}</Snippet>
  },
  renderText: text => text.split('\n')
    .reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [])
}

const RichText = ({ content }) => (
	content && (
		renderRichText(content, options)
	)
)

RichText.propTypes = {
  content: PropTypes.shape({
		raw: PropTypes.string.isRequired,
		references: PropTypes.array
	})
}

export default RichText

// export const ContentfulRichTextFragment = graphql`
// 	fragment ContentfulRichTextFragment on ContentfulRichTextNode {
// 		raw
// 		references {
// 			... on ContentfulAsset {
// 				__typename
// 				contentful_id
// 				title
// 				file {
// 					url
// 				}
// 				gatsbyImageData(
// 					layout: FULL_WIDTH
// 				)
// 			}
// 		}
// 	}
// `