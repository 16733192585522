import React, { useRef, useState, useEffect, useCallback } from 'react';
// Hooks
// import useEventListener from '../hooks/useEventListener';

function useVideoFullscreen() {

  const ref = useRef();
  const [isFullscreen, setFullscreen] = useState(false);

  const onFullscreenChange = event => {
    setFullscreen(o => !o) // toggle
  }

  useEffect(() => {
    const videoElement = ref.current;
    videoElement.addEventListener('webkitfullscreenchange', onFullscreenChange)
    videoElement.addEventListener('mozfullscreenchange', onFullscreenChange)
    videoElement.addEventListener('fullscreenchange', onFullscreenChange)
    videoElement.addEventListener('msfullscreenchange', onFullscreenChange)
    return () => {
      videoElement.removeEventListener('webkitfullscreenchange', onFullscreenChange, false)
      videoElement.removeEventListener('mozfullscreenchange', onFullscreenChange, false)
      videoElement.removeEventListener('fullscreenchange', onFullscreenChange, false)
      videoElement.removeEventListener('msfullscreenchange', onFullscreenChange, false)
    }
  }, [])

  return [ref, isFullscreen]
}

const Video = ({ className, src = "", poster = "", isTouch = false, previewLoop = false }) => {

  const [element, isFullscreen] = useVideoFullscreen();
  const [showClicker, setShowClicker] = useState(false);
  const [muted, setMuted] = useState(true);
  const [isLooping, setLooping] = useState(previewLoop);

  const playVideo = () => {
    if (element.current.requestFullScreen) {
  		element.current.requestFullScreen();
  	} else if (element.current.webkitRequestFullScreen) {
  		element.current.webkitRequestFullScreen();
  	} else if (element.current.mozRequestFullScreen) {
  		element.current.mozRequestFullScreen();
  	}
  }

  useEffect(useCallback(() => {
    if (isFullscreen) {
      setMuted(false)
      setLooping(false)
      element.current.currentTime = 0;
    } else {
      setMuted(true)
      setLooping(previewLoop)
    }
  }, [isFullscreen, setMuted, element]), [isFullscreen])

  const handleProgress = () => {
    if (isLooping && element.current.currentTime > 5) {
      element.current.currentTime = 0
    }
  }

  return (
    <video
      ref={element}
      className={className}
      poster={poster}
      src={src}
      autoPlay={!isTouch}
      playsInline
      muted={muted}
      loop
      controls={isTouch}
      onClick={playVideo}
      onTouchEnd={playVideo}
      onTimeUpdate={handleProgress}
    />
  )
}

export default Video
