// Dependencies
import React, { useRef, useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Link, graphql } from 'gatsby'
import { easeOutInQuint } from '../helpers/easings'

function useScrollAway() {
	const ref = useRef()
	const [isScrolling, set] = useState(false)

	useEffect(() => {
		const checkScroll = () => {
			const top = window.scrollY
			if (top > window.innerHeight*0.25 && !isScrolling) {
				set(true)
			} else if (top <= 0 && isScrolling) {
				set(false)
			}
		}
		checkScroll()
		window.addEventListener('scroll', checkScroll)
		return () => window.removeEventListener('scroll', checkScroll)
	})

	return [ref, isScrolling]
}

const Logotype = ({ 
	willAnimate, 
	svgClassName, 
	onLogoAnimationComplete 
}) => {

	const transition = {
		duration: 1.2,
		ease: easeOutInQuint,
		delay: 3
	}
	
	const wrapperProps = willAnimate ? {
		initial: {
			x: '55%',
			y: '-50%',
			scale: 1.5,
			opacity: 0,
		},
		animate: {
			x: 0,
			y: 0,
			scale: 1,
			opacity: 1
		},
		onAnimationComplete: onLogoAnimationComplete,
		transition: {
			default: transition,
			opacity: {
				delay: 1
			}
		}
	} : {}

	const typeProps = willAnimate ? {
		initial: {
			opacity: 0
		},
		animate: {
			opacity: 1
		},
		transition: {
			...transition,
			delay: 4
		}
	} : {}

	return (
		<motion.div {...wrapperProps}>
			<svg className={svgClassName} width="1319" height="135" viewBox="0 0 1319 135" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g className="ac" fill="#1656CF">
					<path d="M362.456 44.767C362.456 19.042 342.748 1.904 314.471 1.904C278.292 1.904 255.442 29.019 255.442 69.863C255.442 101.796 269.895 134.604 314.3 134.604C326.087 134.604 340.52 131.938 351.945 120.17C362.285 109.47 363.008 97.511 363.545 89.837H334.445C333.912 106.974 322.845 114.267 311.252 114.267C284.327 114.267 284.327 77.707 284.327 70.033C284.327 43.375 292.877 22.067 313.404 22.067C332.122 22.067 333.721 40.252 334.254 44.707L362.456 44.767ZM150.009 131.634H172.668V107.909H150.009V131.634ZM43.167 82.945L62.209 28.01L80.051 82.945H43.167ZM46.557 2.666L0 131.634H26.392L36.027 103.814H87.039L96.312 131.634H124.151L79.365 2.666H46.557Z" />
				</g>
				<motion.g className="vetkovic" fill="#1656CF" {...typeProps}>
					<path fillRule="evenodd" clipRule="evenodd" d="M877.282 69.039C877.282 80.807 879.053 102.228 893.144 117.195C904.931 129.515 921.878 134.866 938.844 134.866C949.544 134.866 970.415 132.543 984.677 116.491C995.207 104.723 1000.92 84.73 1000.92 66.012C1000.92 53.349 998.235 33.184 984.506 17.494C971.767 3.218 952.859 0 939.111 0C892.745 0 877.226 34.789 877.226 69.026L877.282 69.039ZM906.112 68.131C906.112 53.507 908.264 39.569 915.633 30.676C918.479 27.1545 922.103 24.34 926.219 22.453C930.335 20.5661 934.832 19.658 939.358 19.8C952.558 19.8 963.427 26.75 968.777 42.65C971.054 50.0993 972.141 57.8617 971.995 65.65C971.995 86.158 967.539 96.116 963.255 102.572C958.076 110.608 950.402 114.53 939.167 114.53C926.333 114.53 919.726 109.541 915.803 104.19C908.186 94.19 906.168 80.464 906.168 68.144L906.112 68.131Z" />
					<path d="M1316.92 44.767C1316.92 19.042 1297.11 1.904 1268.93 1.904C1232.75 1.904 1209.9 29.019 1209.9 69.863C1209.9 101.796 1224.35 134.604 1268.76 134.604C1280.55 134.604 1294.98 131.938 1306.4 120.17C1316.62 109.592 1317.45 97.7838 1317.98 90.1014L1318 89.837H1288.9C1288.37 106.974 1277.3 114.267 1265.71 114.267C1238.78 114.267 1238.79 77.707 1238.79 70.033C1238.79 43.375 1247.33 22.067 1267.84 22.067C1285.85 22.067 1288.03 38.8553 1288.71 44.1222C1288.74 44.3369 1288.76 44.5325 1288.79 44.707L1316.92 44.767Z" />
					<path d="M1156.01 131.635H1183.66V2.323H1156.01V131.635Z" />
					<path d="M1057.21 131.634L1009.75 3.218H1039.9L1073.6 104.348L1107.5 3.218H1134.87L1087.27 131.634H1057.21Z" />
					<path d="M790.358 131.634H762.71V3.028H790.358V58.5L833.525 2.666H865.8L818.5 60.476L869.914 131.636H835.487L790.358 66V131.634Z" />
					<path d="M673.71 131.634H701.53V24.088H739.88V2.666H635.341V24.088H673.71V131.634Z" />
					<path d="M616.456 131.634H528.156V2.666H614.49V23.726H555.8V54.5H610.735V75.443H555.8V110.234H616.456V131.634Z" />
					<path d="M379.384 3.218L426.836 131.634H457L504.43 3.218H477.143L443.25 104.348L409.55 3.218H379.384Z" />
				</motion.g>
			</svg>
		</motion.div>
	)
}

export default Logotype